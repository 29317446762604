import '../css/index.css'
import screenImg from './screen.png'
import appicon from './appicon.jpg'
import { Helmet } from 'react-helmet-async';

function index() {
    const newTheme = 'nopadding';
    document.body.className = newTheme;
    return (
        <div>
            <Helmet>
                <title>清波记App</title>
                {/* <link rel="icon" href="/favicon.ico" /> */}
            </Helmet>
            <div className='gradienty-pur'>
                <img className='left-image' src={screenImg} />

                <div className='right-div'>
                    <img className='appicon' src={appicon} />
                    <p className='title'>野离子表格</p>
                    <p className='sub-title'>随手记一记，生意有底气</p>
                    <div className='detail'>
                        <p className='detail'>· 极简记账，自动生成单据</p>
                        <p className='detail'>· 自定义仪表盘，纵览账目</p>
                        <p className='detail'>· 支持插图、扫描二维码、条形码</p>
                        <p className='detail'>· 实时用户支持，1分钟让你快速上手</p>
                        <p className='detail'>···</p>
                    </div>
                </div>
            </div>
            <div className='footer-pur'>
                <div style={{ display: 'flex' }}>
                    <a href='./yeiontable/privacyios'><p>《用户隐私协议》</p></a>
                    <a href='./yeiontable/term'><p>《使用条款》</p></a>
                </div>

                <div style={{ display: 'flex', marginTop: 12 }}>
                    <a href='https://beian.miit.gov.cn/'><p style={{ marginRight: 20, fontSize: 14 }}>粤ICP备2021177159号-2</p></a>
                    <p style={{ fontSize: 14 }}>Copyright © 2024 野离子网络科技. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default index;